import React from 'react';
import { graphql } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-gtag-optin';
import { Layout, Link } from '@components';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { BiLinkExternal } from 'react-icons/bi';
interface ArticleProps {
    data: any;
    pageContext: { locale: string };
}
const Article: React.FC<ArticleProps> = ({ data, pageContext: { locale } }) => {
    const articles = data.allStrapiArticle.group;
    const topTitle = locale == 'en' ? 'Wrote about us' : 'Napsali o nás';
    const fieldTitle = fieldValue => {
        if (locale == 'en') {
            return fieldValue == 'en' ? 'English:' : 'Czech:';
        }
        return fieldValue == 'en' ? 'Anglicky:' : 'Česky:';
    };
    return (
        <Layout title={topTitle} lang={locale} path={'/articles'} className="max-w-[100vw]">
            <h1 className="font-bold text-center contentBlock">{topTitle}</h1>
            {articles.map(({ nodes, fieldValue }, index) => (
                <div className="contentBlock" key={index}>
                    <h3 className="font-normal text-center contentBlock">
                        {fieldTitle(fieldValue)}
                    </h3>
                    <div className="flex flex-wrap gap-y-3">
                        {nodes.map(({ Link: href, PostDate, Title }, subIndex) => (
                            <div className="p-3 rounded md:w-1/2 bg-bgcolor/10 " key={subIndex}>
                                <Link to={href} target="_blank" className="">
                                    <h4 className="font-sans font-bold text-lg pb-1">{Title}</h4>
                                    <div className="flex items-center gap-2  text-gray-400">
                                        <p className="max-w-[70vw] md:max-w-[80%] text-xs font-bold text-ellipsis line-clamp-0 md:text-sm">
                                            {href}
                                        </p>
                                        {/* <h6>
                                            <BiLinkExternal />
                                        </h6> */}
                                    </div>
                                </Link>
                                <div className="flex items-center gap-1 pt-1 opacity-90">
                                    <AiOutlineClockCircle />
                                    <h6 className="text-sm font-sans">
                                        {PostDate.split('-')
                                            .reverse()
                                            .map(str => (str.startsWith('0') ? str.slice(1) : str))
                                            .join('. ')}
                                    </h6>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </Layout>
    );
};
export const query = graphql`
    query ArticleQuery($locale: String!) {
        allStrapiArticle(
            sort: { fields: PostDate, order: DESC }
            filter: { locale: { eq: $locale } }
        ) {
            group(field: Language) {
                nodes {
                    Link
                    PostDate
                    Title
                }
                fieldValue
            }
        }
    }
`;
export default Article;
